.notfound-cont{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  height: 100%;
}
.notfound-inner{
  width: 540px;
  margin: auto;
  position: relative;
  text-align: center;
}
.page-404-icon {
  padding-bottom: 30px;
}
.page-404-icon .txt-404{
  position: absolute;
  left: 37%;
  top: 60px;
  font-size: 84px;
  font-weight: 700;
}
.notfound-inner h3{
  font-size: 40px;
  color: #111827;
  font-weight: 600;
}
.notfound-inner p{
  font-size: 20px;
  color: #111827;
  font-weight: 400;
}

.notfound-inner button{
  width: 260px;
  margin: auto;
}