.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-4ggly9.Mui-selected ,
.css-1hrukl9.Mui-selected{
    background-color: #FE9900 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.filter-item p:hover {
    background-color: #FE9900 !important;
}

.filter-item p {
    height: 40px;
    border-radius: 4px;
}

.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary .Mui-focused .MuiInputBase-formControl .MuiInputBase-adornedEnd .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.split-headers .css-t89xny-MuiDataGrid-columnHeaderTitle,
.split-headers .css-mh3zap {
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: initial !important;
    line-height: 1.5 !important;
}