@import "rsuite/dist/rsuite.css";
.tab-container {
  padding: 0 !important;
  display: block;
  width: 100%;
  position: relative;
}
.filer-container {
  position: relative;
  padding: 0 30px;
}
.bulk-operation-container .dropdownContent ul.dropdown-menu {
  width: 100%;
}

.btnBackgoundRemove {
  background-color: #fff;
}
.column-link-container .dropdown-container {
  min-width: 300px;
}

.global-filter {
  width: 500px;
}
.vertical-top {
  vertical-align: top;
}

.cus-dropdown .dropdown-container.open {
  display: block;
}

.btn {
  font-size: 14px !important;
}
.trash {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 4px;
}
