.btn{
    font-size: 14px !important;
}
.StepperContainer-0-2-1{
    padding: 10px 24px 5px 24px;
}
.StepButton-0-2-4.active{
    border:1px solid #00976D !important;
}
.steperDesign .active{
    background-color: #f7e0bd !important;  
    color:#FE9900 !important;
    
}
.steperDesign .completed{
    background-color: #FE9900  !important;  
    color: white !important;   
}
.steperDesign .completed:hover{
    background-color: #00976D !important;     
}



/* Custom Wizard Container */
.wizard-container{
    border: 1px solid #D1D5DB;
    border-radius: 6px;
}
.wizard-container hr{
    border-top-color: #D1D5DB;
}
.wizard-nav-btn-container{
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
}
.wizard-nav-btn-container > div{
    margin-left: 10px;
}
.wizard-nav-btn button{
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    padding: 5px 30px;
}
.wizard-nav-btn button.gray{
    background-color: #9CA3AF;
    color: #ffffff;
    border-color: #9CA3AF;
}
.wizard-nav-btn button.green{
    background-color: #FE9900;
  
    border-color: #FE9900;
    font-size: 14px;
    font-weight: 400;
}
.wizard-container .wizard-stepper-container > div > div > div > button {
    background-color: #E5E7EB;
    border: 1px solid #E5E7EB;
    flex: 0.9 1;
    text-transform: capitalize !important
}
.wizard-nav-btn button.outline{
    background-color: #ffffff;
    color: #FE9900;
    border: 1px solid #FE9900;  
    font-size: 14px;
    font-weight: 400;
}
.wizard-container .wizard-stepper-container {
    /* height: 100px !important;
    flex-wrap: wrap; */
}
.wizard-container .wizard-stepper-container > div > div > div > button{
    background-color: #E5E7EB;
    border: 1px solid #E5E7EB;
    /* flex:0.9; */
    color: #111827;;
    font-size: 16px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
.wizard-container .wizard-stepper-container > div > div > div > button.active{
    background-color:#f7e0bd !important ;
    border: 1px solid #FE9900 !important;
}
.wizard-container .wizard-stepper-container > div > div > div > button span{
    font-size: 16px;
    font-weight: 600;
    color: #6B7280;
}
.wizard-container .wizard-stepper-container > div > div > div > button.completed{
    background-color: #FE9900 !important;
}
.wizard-container .wizard-stepper-container > div > div > div > button.completed span{
    color: #ffffff;
}
.wizard-container .wizard-step-container .wizard-step{
    padding:10px 20px;
    min-height: 300px;
}
.wizard-container .wizard-step-container .wizard-step .card{ 
    background-color: #F9FAFB;
    border-radius: 6px;
    padding: 5px 15px 5px 15px;
    border: 0px;
    margin: 0px 0;
    padding: 10px;
}
.wizard-container .wizard-step-container .wizard-step .card .form-select,.wizard-container .wizard-step-container .wizard-step .card .form-control{
    height: 40px;
}
.wizard-container .wizard-step-container .card .title{
    font-size: 16px;
    font-weight: 600;
    color: #111827;
}
.wizard-container .wizard-step-container .card .form-row{
    display: flex;
}
.wizard-container .wizard-step-container .card .form-row > div.form-field-group{
    display: flex;
    flex: auto;
}
.wizard-container .wizard-step-container .card .form-row > div.form-field-group .form-field{
    margin-right: 10px;
    flex: auto;
}
.wizard-container .wizard-step-container .card .form-action{
    display: flex;
}
.wizard-container .wizard-step-container .card .form-action.a-2{
    width: 100px;
}
.wizard-container .wizard-step-container .card .form-action.a-3{
    width: 150px;
}
.wizard-container .wizard-step-container .card .form-action.a-4{
    width: 200px;
}
.wizard-container .wizard-step-container .card .form-action > div{
    margin-left: 10px;
    text-align: center;
}
.wizard-container .wizard-step-container .card .icon-box-white{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #FFFFFF;
    border: 1px solid #D1D5DB;
    padding: 5px;
}
.wizard-container .wizard-step-container .card .icon-box-gray{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #F3F4F6;
    padding: 5px;
}

.wizard-container .wizard-step-container .add-more-btn{
    color: #FE9900 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 0px;
  }
/* .wizard-container .wizard-step-container .delete-btn{
    color: #FE9900 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 0px;
  } */
  .wizard-container .wizard-step-container .add-more-btn :hover{
    background-color: none !important
  }
  .wizard-container .wizard-step-container .delete-btn :hover{
    background-color: none !important
  }
  .wizard-container .wizard-step-container .add-more-btn i{
   background-image: url("../../assets/images//icons/circle-add-icon.svg");
   background-repeat: no-repeat;
   background-position: center;
   padding: 8px;
   margin-right: 5px;
  }
  .wizard-container .wizard-step-container .delete-btn i{
   background-image: url("../../assets/images//icons/circle-minus-svgrepo-com.svg");
   background-repeat: no-repeat;
   background-position: center;
   padding: 8px;
   margin-right: 5px;
  }
  .wizard-container .wizard-step-container .card .gray-box .option-list-container .form-check .form-check-input{
    background-color: transparent;
    border-color: #9CA3AF;
  }
  .wizard-container .wizard-step-container .card .gray-box .option-list-container .form-check .form-check-input:checked[type=checkbox]{
    background-color: #00976D;
    border-color: #00976D;
  }
  .wizard-container .wizard-step-container .card .gray-box .option-list-container .form-check label{
    font-size: 16px;
    font-weight: 400;
    color: #374151;
  }

  .list-container {
    display: flex;
    font-size: 18px;
    background-color: #eee;
    flex-direction: column;
  }
  .item-container {
    background-color: #fff;
    border: 1px solid black;
    padding: 25px 70px;
    margin: 15px 50px;
  }
  .rule-switch-container{
    display: flex;
  }
  .multiply-sympol-box{
    padding: 5px;
  }
  .css-t3ipsp-control:hover, .css-t3ipsp-control:active, .css-t3ipsp-control:focus{
    box-shadow: 0 0 0 1px #00ad7c;
    border-color: #00ad7c !important;
  }
  .MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary .Mui-selected{
    color: #FE9900 !important;
  }

  .MuiTabs-flexContainer{
    justify-content: space-between;
    background-color: #E5E7EB;
  }